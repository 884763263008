import {createContext, ReactNode, useReducer} from "react";

type SnackbarContextType = {
    msg: string,
    severity: "error" | "warning" | "info" | "success",
    setMsg: (data: MsgType) => void,
};

type MsgType = {
    msg: string;
    severity?: "error" | "warning" | "info" | "success";
}

type ActionType = {
    type: string;
    value: MsgType;
};

const SET_MSG = "setMsg";

const INITIAL_SNACKBAR_STATE: SnackbarContextType = {
    msg: "",
    severity: "error",
    setMsg: (data: MsgType) => {},
};

const contentReducer = (state: SnackbarContextType, action: ActionType): SnackbarContextType => {
    switch (action.type) {
        case SET_MSG: {
            return {
                ...state,
                msg: action.value.msg,
                severity: action.value.severity ? action.value.severity : state.severity,
            };
        }
        default:
            return state;
    }
};

export const SnackbarContextProvider = ({children} : {children: ReactNode} ) => {
    const [snackbarContext, dispatch] = useReducer(contentReducer, INITIAL_SNACKBAR_STATE);

    const setMsg = (data: MsgType) => {dispatch({type: SET_MSG, value: data});};

    const contextValue: SnackbarContextType = {
        ...snackbarContext,
        setMsg: setMsg,
    };
    return <SnackbarContext.Provider value={contextValue}>{children}</SnackbarContext.Provider>;
};

const SnackbarContext = createContext<SnackbarContextType>({
    ...INITIAL_SNACKBAR_STATE,
});

export default SnackbarContext;
