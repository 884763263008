import { WPImage } from "./WPImage";
// @ts-ignore
import slugify from "slugify";

type SocialButton = {
  color_button: string;
  color_font: string;
  link: any;
}

export class Nutrition {
  quantity: string;
  calories: string;
  fat: string;
  saturatedFat: string;
  carbs: string;
  sugar: string;
  alcohols?: string;
  proteins: string;
  salt: string;

  constructor(
    quantity: string,
    calories: string,
    fat: string,
    saturatedFat: string,
    carbs: string,
    sugar: string,
    alcohols: string,
    proteins: string,
    salt: string
  ) {
    this.quantity = quantity;
    this.calories = calories;
    this.fat = fat;
    this.saturatedFat = saturatedFat;
    this.carbs = carbs;
    this.sugar = sugar;
    this.alcohols = alcohols;
    this.proteins = proteins;
    this.salt = salt;
  }

  public static fromApiResponse(data: any) {
    return new Nutrition(
      data.quantity,
      data.calories,
      data.fat,
      data.saturated_fat,
      data.carbs,
      data.sugar,
      data.alcohols,
      data.proteins,
      data.salt
    );
  }
}

export class Product {
  shortDescription: string;
  mainImage: string;
  marginTop: number;
  rowHeight: number;
  textColor: string;
  productName: string;
  tileImage: string;
  title: string;
  shortTitle: string;
  description: string;
  ingredients: string;
  nutrition: Nutrition[];
  references: any;
  gallery: WPImage[];
  slug: string;
  brandsID: any;
  brandColor: string;
  socialButton: SocialButton;

  constructor(
    shortDescription: string,
    mainImage: string,
    marginTop: number,
    rowHeight: number,
    textColor: string,
    productName: string,
    tileImage: string,
    title: string,
    shortTitle: string,
    description: string,
    ingredients: string,
    nutrition: Nutrition[],
    references: any,
    gallery: WPImage[],
    slug: string,
    brands: any,
    brandColor: string,
    socialButton: SocialButton,
  ) {
    this.shortDescription = shortDescription;
    this.mainImage = mainImage;
    this.marginTop = marginTop;
    this.rowHeight = rowHeight;
    this.textColor = textColor;
    this.productName = productName;
    this.tileImage = tileImage;
    this.title = title;
    this.shortTitle = shortTitle;
    this.description = description;
    this.ingredients = ingredients;
    this.nutrition = nutrition;
    this.references = references;
    this.gallery = gallery;
    this.slug = slug;
    this.brandsID = brands;
    this.brandColor = brandColor;
    this.socialButton = socialButton;
  }

  public static fromApiResponse(data: any) {
    const acf = data.acf;
    return new Product(
      acf.short_description,
      acf.main_image,
      acf.margin_top,
      acf.row_height,
      acf.text_color,
      acf.product_name,
      acf.tile_image,
      acf.title,
      acf.short_title,
      acf.description,
      acf.ingredients,
      acf.nutrition && acf.nutrition.length > 0 && acf.nutrition.map((n: any) => Nutrition.fromApiResponse(n)),
      acf.references,
      acf.gallery,
      slugify(acf.product_name).toLowerCase(),
      data.brands,
      data._embedded["wp:term"][0][0]["acf"]["color"],
      acf.social_button
    );
  }
}
