const FacebookIcon = () => {
    return (
        <svg width="127" height="127" viewBox="0 0 127 127" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="63.4038" cy="63.4038" r="63.4038" fill="#E6007E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.1731 64.5167C27.1731 82.9798 40.5825 98.3326 58.1202 101.446V74.6241H48.8361V64.3094H58.1202V56.0558C58.1202 46.7717 64.1023 41.6159 72.5632 41.6159C75.2432 41.6159 78.1337 42.0275 80.8137 42.4391V51.9305H76.0695C71.5296 51.9305 70.4991 54.199 70.4991 57.0894V64.3094H80.4021L78.7527 74.6241H70.4991V101.446C88.0368 98.3326 101.446 82.9829 101.446 64.5167C101.446 43.9771 84.7347 27.1729 64.3096 27.1729C43.8845 27.1729 27.1731 43.9771 27.1731 64.5167Z" fill="white"/>
        </svg>
    )
}

export default FacebookIcon
