const TikTokIcon = () => {
    return (
        <svg width="128" height="127" viewBox="0 0 128 127" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="64.0192" cy="63.4038" r="63.4038" fill="#E6007E"/>
            <path d="M96.627 47.4316H96.6166C96.0287 47.4894 95.4378 47.5195 94.8468 47.522C88.3654 47.5226 82.3199 44.2339 78.7701 38.7745V68.5611C78.7701 80.7194 68.9793 90.5762 56.9023 90.5762C44.8254 90.5762 35.0346 80.72 35.0346 68.5617C35.0346 56.4033 44.8254 46.546 56.9023 46.546C57.359 46.546 57.8052 46.5872 58.2539 46.6154V57.4641C57.8052 57.41 57.3639 57.3271 56.9023 57.3271C50.7384 57.3271 45.7417 62.3574 45.7417 68.5629C45.7417 74.7684 50.7384 79.7988 56.9023 79.7988C63.0663 79.7988 68.5123 74.9085 68.5123 68.7024L68.6197 18.1152H78.9294C79.9013 27.4225 87.3563 34.6925 96.627 35.3747V47.4316Z" fill="white"/>
        </svg>
    )
}

export default TikTokIcon
