import Block from "../model/Block";
import ProductsMainPage from "../components/products/ProductsMainPage";
import MainImageWithText from "../components/MainImageWithText";
import WhereBuySection from "../components/WhereBuySection";
import SocialMediaSection from "../components/SocialMediaSection";
import GalleryLogos from "../components/GalleryLogos";
import ProductsGallery from "../components/products/ProductsGallery";
import Slider from "../components/Slider";
import BrandsSwitch from "../components/products/BrandsSwitch";

const getComponents = (blocks: Block[]) => {
  return blocks.map((block, index) => {
    switch (block.component) {
      case "slider":
        return <Slider key={index} content={block.content} />;
      case "main_image_with_text":
        return <MainImageWithText key={index} content={block.content} />;
      case "where_buy_section":
        return <WhereBuySection key={index} content={block.content} />;
      case "products_main_page":
        return <ProductsMainPage key={index} />;
      case "social_media_section":
        return <SocialMediaSection key={index} content={block.content} />;
      case "brands_switch":
        return <BrandsSwitch key={index} />;
      case "products_gallery":
        return <ProductsGallery key={index} />;
      case "gallery_logos":
        return <GalleryLogos key={index} content={block.content} />;
      default:
        return <></>;
    }
  });
};

export default getComponents;
