import axios from "axios";

export const getRequest =  async (path: string, onError?: (error: any) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + path : "";
    let data = [] as any[];
    const config = {
        headers: {
            // "Accept-Content": "application/json" FOR LARAVEL ONLY
        }
    }
    try {
        const response = await axios.get(url, config)
        if (response.status == 200) {
            data = response.data;
        } else {
            onError && onError(response);
        }
    } catch(error: any) {
        console.error(error.response? error.response : error);
        onError && onError(error.response ? error.response : "Błąd serwera!");
    }
    return data;
}

export const getAuthRequest =  async (path: string, token: string,  redirectToLogin: () => void, onError?: (error: any) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + path : "";
    let data = [] as any[];
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
    try {
        const response = await axios.get(url, config)
        if (response.status == 200) {
            data = response.data;
        } else {
            onError && onError(response);
        }
    } catch(error: any) {
        if(error.response.status === 401 || error.response.status === 403) {
            redirectToLogin()
        }
        console.error(error.response? error.response : error);
        onError && onError(error.response ? error.response : "Błąd serwera!");
    }
    return data;
}

export const postAuthRequest =  async (path: string, data: any, token: string,  redirectToLogin: () => void, onError?: (error: any) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + path : "";
    let responseData = [] as any[];
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
    try {
        const response = await axios.post(url, data, config)
        if (response.status == 200) {
            responseData = response.data;
        } else {
            onError && onError(response);
        }
    } catch(error: any) {
        if(error.response.status === 401 || error.response.status === 403) {
            redirectToLogin()
        }
        console.error(error.response? error.response : error);
        onError && onError(error.response ? error.response : "Błąd serwera!");
    }
    return responseData;
}

export const putAuthRequest =  async (path: string, data: any, token: string,  redirectToLogin: () => void, onError?: (error: any) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + path : "";
    let responseData = [] as any[];
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
    try {
        const response = await axios.put(url, data, config)
        if (response.status == 200) {
            responseData = response.data;
        } else {
            onError && onError(response);
        }
    } catch(error: any) {
        if(error.response.status === 401 || error.response.status === 403) {
            redirectToLogin()
        }
        console.error(error.response? error.response : error);
        onError && onError(error.response ? error.response : "Błąd serwera!");
    }
    return responseData;
}

export const deleteAuthRequest =  async (path: string, token: string,  redirectToLogin: () => void, onError?: (error: any) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + path : "";
    let responseData = [] as any[];
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
    try {
        const response = await axios.delete(url, config)
        if (response.status == 200) {
            responseData = response.data;
        } else {
            onError && onError(response);
        }
    } catch(error: any) {
        if(error.response.status === 401 || error.response.status === 403) {
            redirectToLogin()
        }
        console.error(error.response? error.response : error);
        onError && onError(error.response ? error.response : "Błąd serwera!");
    }
    return responseData;
}