const MenuIcon = () => {
    return <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.75 8.75H26.25C27 8.75 27.5 8.25 27.5 7.5C27.5 6.75 27 6.25 26.25 6.25H3.75C3 6.25 2.5 6.75 2.5 7.5C2.5 8.25 3 8.75 3.75 8.75ZM27.5 15C27.5 15.75 27 16.25 26.25 16.25H3.75C3 16.25 2.5 15.75 2.5 15C2.5 14.25 3 13.75 3.75 13.75H26.25C27 13.75 27.5 14.25 27.5 15ZM3.75 21.25H26.25C27 21.25 27.5 21.75 27.5 22.5C27.5 23.25 27 23.75 26.25 23.75H3.75C3 23.75 2.5 23.25 2.5 22.5C2.5 21.75 3 21.25 3.75 21.25Z" fill="black"/>
        <mask id="mask0_608_4941" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="2" y="6" width="26" height="18">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.75 8.75H26.25C27 8.75 27.5 8.25 27.5 7.5C27.5 6.75 27 6.25 26.25 6.25H3.75C3 6.25 2.5 6.75 2.5 7.5C2.5 8.25 3 8.75 3.75 8.75ZM27.5 15C27.5 15.75 27 16.25 26.25 16.25H3.75C3 16.25 2.5 15.75 2.5 15C2.5 14.25 3 13.75 3.75 13.75H26.25C27 13.75 27.5 14.25 27.5 15ZM3.75 21.25H26.25C27 21.25 27.5 21.75 27.5 22.5C27.5 23.25 27 23.75 26.25 23.75H3.75C3 23.75 2.5 23.25 2.5 22.5C2.5 21.75 3 21.25 3.75 21.25Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_608_4941)">
            <rect width="30" height="30" fill="#424242"/>
        </g>
    </svg>
}

export default MenuIcon;
