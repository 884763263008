import { Product } from "../model/Product";
import MainLayout from "../layout/MainLayout";
import { Box, Collapse, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PinkTitle from "../ui/PinkTitle";
import ChoctopusXEkipa from "../ui/ChoctopusXEkipa";
import { useState } from "react";
import ArrowButton from "../ui/ArrowButton";
import NutritionTable from "../components/products/NutritionTable";
import CircleArrowLeft from "../ui/icons/CircleArrowLeft";
import CircleArrowRight from "../ui/icons/CircleArrowRight";
import DOMPurify from "dompurify";
import InstagramOnlyIcon from "../ui/icons/InstagramOnlyIcon";

const MainImage = styled("img")({});

const TileImage = styled("img")({
  marginBottom: "auto",
  maxWidth: "30%",
  width: "30%",
  padding: "0 10px",
});

type Props = {
  product: Product;
};

type CollapsableHeaderProps = {
  title: string;
  up: boolean;
  onClick: () => void;
};

const CollapsableHeader = ({ title, onClick, up }: CollapsableHeaderProps) => {
  const { palette } = useTheme();

  return (
    <Grid
      item
      container
      wrap={"nowrap"}
      sx={{
        backgroundColor: "white",
        justifyContent: "space-between",
        borderRadius: "5px",
        alignItems: "center",
        height: "60px",
        mt: "26px",
      }}>
      <Typography variant={"body2"} sx={{ ml: "24px", color: palette.secondary.main, fontWeight: 700 }}>
        {title}
      </Typography>
      <ArrowButton degrees={up ? 0 : 180} sx={{ mr: "24px" }} onClick={onClick} />
    </Grid>
  );
};

const ProductPage = ({ product }: Props) => {
  const { palette, typography, breakpoints } = useTheme();
  const sm = useMediaQuery(breakpoints.down("sm"));
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [isIngredientsExpanded, setIsIngredientsExpanded] = useState(false);
  const [isNutritionExpanded, setIsNutritionExpanded] = useState(false);

  const getLeftTileIndex = () => {
    return galleryIndex > 1 ? galleryIndex - 1 : product.gallery.length - 1;
  };

  const getRightTileIndex = () => {
    return galleryIndex < product.gallery.length - 1 ? galleryIndex + 1 : 0;
  };

  console.log(product)

  return (
    <MainLayout>
      <Grid
        item
        container
        flexDirection={"column"}
        alignItems={"center"}
        component={"section"}
        sx={{
          backgroundColor: `${product.brandColor}`,
          zIndex: 105,
          pb: "1200px",
          mb: { xs: "-1200px", md: "-1000px", lg: "-1200px" },
        }}>
        <Grid
          item
          container
          flexDirection={"column"}
          justifyContent={"end"}
          sx={{
            zIndex: 106,
            minHeight: { xs: "290px", sm: "280px", md: "260px" },
            ml: { xs: "-15%", sm: "-10%" },
            transform: "rotate(-11deg)",
          }}>
            <Box sx={{display: 'flex', width: {xs: '100%', sm: 'auto'}}}>
              <PinkTitle
                title={product.shortTitle}
                sx={{
                  '@media (max-width: 400px)': {
                    fontSize: '36px!important'
                  },
                  '@media (min-width: 400px) and (max-width: 600px)': {
                    fontSize: '48px!important'
                  },
                  whiteSpace: 'nowrap',
                  fontSize: {sm:"60px !important"},
                  height: {xs:"75px",sm:"75px"},
                  // width: { xs: "70%", sm: "60%", md: "39%", lg: "30%" },
                  pl: "20%",
                  lineHeight: { xs: "80px", sm: "80px" },

                }}
              />
            </Box>
          <ChoctopusXEkipa
            sx={{
              height: { xs: "45px", lg: "50px" },
            }}
          />
        </Grid>
        <Grid
          item
          container
          alignItems={{ xs: "center", lg: "start" }}
          flexDirection={{ xs: "column", lg: "row" }}
          sx={{
            zIndex: 105,
          }}
          mt={{ xs: 10, lg: 8, xl: 10 }}>
          <Grid item container xs={12} lg={5} justifyContent={"center"} alignItems={"center"}>
            <MainImage
              src={product.gallery[galleryIndex].url}
              alt={product.gallery[galleryIndex].alt}
              sx={(theme) => ({
                marginBottom: "auto",
                [theme.breakpoints.down(1600)]: {
                  maxWidth: `${product.gallery[galleryIndex].width < 600 ? "45%" : "62%"}`,
                },
                [theme.breakpoints.up(1600)]: {
                  maxWidth: `${product.gallery[galleryIndex].width < 600 ? "40%" : "62%"}`,
                },
              })}
            />
            <Grid item container
              justifyContent={"center"}
              sx={{
                maxWidth: "80%",
                mt: "40px",
              }}
              wrap={"nowrap"}
            >
              <Box
                onClick={() => setGalleryIndex(getLeftTileIndex())}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <CircleArrowLeft/>
              </Box>
              <TileImage src={product.gallery[getLeftTileIndex()].sizes.medium}
                alt={product.gallery[getLeftTileIndex()].alt}/>
              <TileImage src={product.gallery[getRightTileIndex()].sizes.medium}
                alt={product.gallery[getRightTileIndex()].alt}/>
              <Box
                onClick={() => setGalleryIndex(getRightTileIndex())}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <CircleArrowRight/>
              </Box>
            </Grid>
            {product.socialButton && product.socialButton.link && product.socialButton.link.url  && product.socialButton.link.title
              && product.socialButton.color_button && product.socialButton.color_font ? <Grid item container
              justifyContent={"center"}
              sx={{
                mt: "40px",
                mx: {xs: '30px', sm: "40px"}
              }}
              wrap={"nowrap"}
            >
              <Box component={"a"} href={product.socialButton.link.url} target={product.socialButton.link.target}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  maxWidth: {xs: '100%', sm: '48%', lg: '100%', xl: '48%'},
                  // flex: {xs: '1 0 100%', sm: '0 0 58%', lg: 'auto', xl: '0 0 58%'},
                  gap: {xs: '20px', sm: '40px'},
                  backgroundColor: product.socialButton.color_button
                    ? product.socialButton.color_button : palette.secondary.main,
                  color: product.socialButton.color_font
                    ? product.socialButton.color_font : 'white',
                  textDecoration: 'none',
                  fontSize: typography.h6,
                  transition: 'all .4s ease',
                  '&:hover': {
                    filter: 'brightness(90%)'
                  }
                }}
                px={4}
                py={2}
              >
                <InstagramOnlyIcon fill={product.socialButton.color_font && product.socialButton.color_font} />{product.socialButton.link.title}
              </Box>
            </Grid> : null}
          </Grid>
          <Grid item container xs={10} lg={5} justifyContent={"center"}>
            <Typography
              variant={sm ? "h4" : "h2"}
              sx={{
                color: "white",
                mt: { xs: "40px", lg: "-1em" },
                width: "100%",
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.title) }}
            />
            <Typography
              variant={"body1"}
              sx={{
                color: "white",
                fontWeight: 700,
                mt: "10px",
              }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.description) }}></Typography>
            <CollapsableHeader
              title={"Skład"}
              onClick={() => setIsIngredientsExpanded(!isIngredientsExpanded)}
              up={isIngredientsExpanded}
            />
            <Collapse in={isIngredientsExpanded}>
              <Typography sx={{ color: "white", fontWeight: 700, m: 2 }}>{product.ingredients}</Typography>
            </Collapse>
            <CollapsableHeader
              title={"Wartości odżywcze"}
              onClick={() => setIsNutritionExpanded(!isNutritionExpanded)}
              up={isNutritionExpanded}
            />
            <Collapse in={isNutritionExpanded} sx={{ width: "100%" }}>
              <NutritionTable nutrition={product.nutrition} references={product.references} />
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ProductPage;
