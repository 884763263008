import {Nutrition} from "../../model/Product";
import {Grid, styled, Typography, useTheme} from "@mui/material";
import DOMPurify from "dompurify";

type Props = {
    nutrition: Nutrition[];
    references: any;
}

const TableText = styled(Typography)(({theme}) => ({
    color: theme.palette.secondary.main,
    fontWeight: 700,

    [theme.breakpoints.down('lg')]: {
        fontSize: "18px !important",
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: "12px !important",
    },
}))

const TableValueText = styled(TableText)(({theme}) => ({
    textAlign: "center"
}))

const NutritionTable = ({nutrition, references}: Props) => {
    const {palette} = useTheme();

    return (
        <Grid item container
              flexDirection={"column"}
              sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  mt: "16px",
              }}
        >
            <Grid item container
                  sx={{
                      borderBottom: `1px solid ${palette.primary.main}`,
                      maxWidth: "calc(100% - 50px)",
                      m: "0 25px",
                      p: "25px 0"
                  }}
            >
                <Grid item container xs={4}>
                    <TableText>Wartość odżywcza</TableText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[0].quantity}</TableValueText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[1].quantity}</TableValueText>
                </Grid>
            </Grid>
            <Grid item container
                  sx={{
                      borderBottom: `1px solid ${palette.primary.main}`,
                      maxWidth: "calc(100% - 50px)",
                      m: "0 25px",
                      p: "25px 0"
                  }}
            >
                <Grid item xs={4}>
                    <TableText>Wartość energetyczna</TableText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[0].calories}</TableValueText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[1].calories}</TableValueText>
                </Grid>
            </Grid>
            <Grid item container
                  sx={{
                      maxWidth: "calc(100% - 50px)",
                      m: "0 25px",
                      p: "25px 0 0 0"
                  }}
            >
                <Grid item xs={4}>
                    <TableText>Tłuszcz g</TableText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[0].fat}</TableValueText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[1].fat}</TableValueText>
                </Grid>
            </Grid>
            <Grid item container
                  sx={{
                      maxWidth: "calc(100% - 50px)",
                      m: "0 25px",
                      p: "10px 0 0 0"
                  }}
            >
                <Grid item xs={4}>
                    <TableText>-w tym kwasy tłuszczowe</TableText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[0].saturatedFat}</TableValueText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[1].saturatedFat}</TableValueText>
                </Grid>
            </Grid>
            <Grid item container
                  sx={{
                      borderBottom: `1px solid ${palette.primary.main}`,
                      maxWidth: "calc(100% - 50px)",
                      m: "0 25px",
                      pb: "10px"
                  }}
            >
                <Grid item xs={4}>
                    <TableText>nasycone g</TableText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                </Grid>
            </Grid>
            <Grid item container
                  sx={{
                      maxWidth: "calc(100% - 50px)",
                      m: "0 25px",
                      p: "25px 0 0 0"
                  }}
            >
                <Grid item xs={4}>
                    <TableText>Węglowodany g</TableText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[0].carbs}</TableValueText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[1].carbs}</TableValueText>
                </Grid>
            </Grid>
            <Grid item container
                  sx={{
                      borderBottom: nutrition[0].alcohols && nutrition[1].alcohols ? 'none' : `1px solid ${palette.primary.main}`,
                      maxWidth: "calc(100% - 50px)",
                      m: "0 25px",
                      p: nutrition[0].alcohols && nutrition[1].alcohols ? "10px 0 10px 0" : "10px 0 25px 0"
                  }}
            >
                <Grid item xs={4}>
                    <TableText>-w tym cukry g</TableText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[0].sugar}</TableValueText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[1].sugar}</TableValueText>
                </Grid>
            </Grid>
            {nutrition[0].alcohols && nutrition[1].alcohols ? <Grid item container
                  sx={{
                    borderBottom: `1px solid ${palette.primary.main}`,
                    maxWidth: "calc(100% - 50px)",
                    m: "0 25px",
                    p: "0 0 25px 0"
                  }}
            >
              <Grid item xs={4}>
                <TableText>-alkohole wielowodorotlenowe (poliole) g</TableText>
              </Grid>
              <Grid item container justifyContent={"center"} xs={4}>
                <TableValueText>{nutrition[0].alcohols}</TableValueText>
              </Grid>
              <Grid item container justifyContent={"center"} xs={4}>
                <TableValueText>{nutrition[1].alcohols}</TableValueText>
              </Grid>
            </Grid> : null}
            <Grid item container
                  sx={{
                      borderBottom: `1px solid ${palette.primary.main}`,
                      maxWidth: "calc(100% - 50px)",
                      m: "0 25px",
                      p: "25px 0"
                  }}
            >
                <Grid item xs={4}>
                    <TableText>Białko g</TableText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[0].proteins}</TableValueText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[1].proteins}</TableValueText>
                </Grid>
            </Grid>
            <Grid item container
                  sx={{
                      maxWidth: "calc(100% - 50px)",
                      m: "0 25px",
                      p: "25px 0"
                  }}
            >
                <Grid item xs={4}>
                    <TableText>Sól g</TableText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[0].salt}</TableValueText>
                </Grid>
                <Grid item container justifyContent={"center"} xs={4}>
                    <TableValueText>{nutrition[1].salt}</TableValueText>
                </Grid>
            </Grid>
            <Grid item container
                  sx={{
                      maxWidth: "100%",
                      p: "15px"
                  }}
            >
                <TableText>
                    <Typography sx={{fontSize: "16px!important", fontWeight: '700!important'}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(references)}} />
                </TableText>
            </Grid>
        </Grid>
    )
}

export default NutritionTable;
