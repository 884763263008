import PinkTitle from "../ui/PinkTitle";
import {Grid} from "@mui/material";

type Props = {
    children: any;
}

const Maintenance = ({children}: Props) => {
    const env = `${process.env.REACT_APP_ENV}`;

    const buildPage = <Grid item container justifyContent={"center"} alignItems={"center"} sx={{minHeight: "100vh"}}>
        <PinkTitle title={"Strona w budowie"}/>
    </Grid>

    return (
        <>
            {
                env === "maintenance" ? buildPage : children
            }
        </>
    )
}

export default Maintenance;