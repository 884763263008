import {Box, useMediaQuery, useTheme} from "@mui/material";
import FullHeader from "./FullHeader";
import MinHeader from "./MinHeader";
import backgroundHeader from "../../assets/images/background-header.svg";

const Header = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <>
            <Box component={"img"} src={backgroundHeader} mt={{xs: -2, sm: -4, lg: -12}} sx={{position: 'absolute', top: 0, left: 0, zIndex: 99, width: {xs: '300px', sm: '500px', lg: '1000px'}, objectFit: 'cover'}} />
            {matches ? <FullHeader /> : <MinHeader />}
        </>
    )
}

export default Header;
