import PinkTitle from "../../ui/PinkTitle";
import { Grid, Box, useTheme, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ContentContext from "../../store/content-context";
import YellowButton from "../../ui/YellowButton";
import TentacleProducts from "../../assets/images/tentacle_products_page.svg";
import TentacleProductsSide from "../../assets/images/tentacle_products3.svg";
import { useLocation } from "react-router-dom";

const BrandsSwitch = () => {
  const { products, brands } = useContext(ContentContext);
  const { palette, breakpoints } = useTheme();
  const md = useMediaQuery(breakpoints.down("md"));
  const lg = useMediaQuery(breakpoints.down("lg"));

  const [currentBrand, setCurrentBrand] = useState(-1);
  const [offsetY, setOffsetY] = useState(0);
  const [listOfBackgroundBlocks, setListOfBackgroundBlocks] = useState<any[]>([1860]);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  let { state } = useLocation();

  useEffect(() => {
    state && setCurrentBrand(state.index);
  }, []);

  let countBlocks: any[] = [];
  for (let i = 1; i <= products.length; i++) {
    if (i % 4 === 0) {
      countBlocks.push(i);
    }
  }

  useEffect(() => {
    listOfBackgroundBlocks.length < countBlocks.length &&
      setListOfBackgroundBlocks((prevList) => [...prevList, prevList[prevList.length - 1] + 1700]);
  }, [countBlocks]);

  return (
    <Box sx={{ position: "relative", zIndex: 98 }} component={"section"} mb={{ xs: 0, md: 10, lg: 80 }}>
      <Box
        component={"img"}
        src={TentacleProducts}
        sx={{
          position: "absolute",
          display: { xs: "none", md: "block" },
          top: { xs: "50%", lg: "640px" },
          left: 0,
          zIndex: 100,
          transform: `translateY(-${offsetY * 0.5}px)`,
        }}
      />
      {listOfBackgroundBlocks.map((pixels: any, index: number) => {
        return (
          <Box
            key={index}
            display={currentBrand !== -1 ? (index > 0 ? "block" : "none") : "none"}
            component={"img"}
            src={TentacleProductsSide}
            sx={{
              position: "absolute",
              top: { xs: "50%", lg: `${index > 1 ? pixels : pixels * 0.6}px` },
              left: `${index % 2 === 0 ? "0" : "auto"}`,
              right: 0,
              zIndex: 100,
              transform: `scaleX( ${index % 2 === 0 ? "1" : "-1"}) translateY(-${offsetY * 0.5}px)`,
              [breakpoints.down("lg")]: {
                display: "none",
              },
            }}
          />
        );
      })}
      <Grid
        container
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        sx={(theme) => ({
          position: "relative",
          [theme.breakpoints.up(1300)]: { width: "1172px" },
          [theme.breakpoints.down(1300)]: { width: "1000px" },
          [theme.breakpoints.down(1200)]: { width: "100%" },
          margin: { xs: "160px auto 0 auto", lg: "160px auto 160px auto", xl: "160px auto 160px auto" },
        })}>
        <PinkTitle title={"NASZE WSPÓŁPRACE"} sx={{ whiteSpace: "normal", margin: "36px auto", zIndex: 100 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "center", lg: "flex-end" },
            gap: { xs: "40px", lg: "80px" },
            flexWrap: "wrap",
            flexDirection: { xs: "column", lg: "row" },
            margin: { xs: "30px auto 50px auto", lg: "30px auto 100px auto" },
            zIndex: 101,
          }}>
          {brands &&
            brands.map((brand: any, index: number) => (
              <Box
                key={index}
                bgcolor={brand.colorCard ? brand.colorCard : brand.color}
                width={"531px"}
                height={"212px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  opacity: `${currentBrand !== index ? (currentBrand === -1 ? "100%" : "40%") : "100%"}`,
                  cursor: "pointer",
                  boxShadow: "-30px 45px 4px 0px #00000040",
                  "&:hover": {
                    filter: `${currentBrand !== 1 && "saturate(125%)"}`,
                    boxShadow: `${currentBrand !== 1 && "-30px 45px 4px 0px #00000055"}`,
                  },
                  [breakpoints.down("sm")]: { width: "360px", height: "140px" },
                }}
                onClick={() => {
                  setCurrentBrand(index);
                }}>
                <Box
                  component={"img"}
                  src={brand.image}
                  height={"150px"}
                  sx={{
                    [breakpoints.down("sm")]: { height: "100px" },
                  }}
                />
              </Box>
            ))}
        </Box>
        {products ? (
          currentBrand !== -1 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: { xs: "center", lg: "flex-end" },
                gap: { xs: "80px", lg: "150px" },
                flexWrap: "wrap",
                flexDirection: { xs: "column-reverse", lg: "row-reverse" },
                margin: { xs: "30px auto 50px auto", lg: "30px auto 0 auto" },
                zIndex: 101,
              }}>
              {products.map((item: any, index: number) => {
                return (
                  <Box
                    key={index}
                    display={item.brandsID[0] === brands[currentBrand].id ? "flex" : "none"}
                    sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Box component={"img"} src={item.tileImage} sx={{ width: { xs: "100%", lg: "380px" } }} />
                    <Typography
                      variant="body2"
                      color={
                        (item.textColor !== "white" && lg) || (index !== products.length - 1 && lg)
                          ? palette.primary.dark
                          : "white"
                      }
                      mt={0}
                      sx={{
                        fontWeight: 700,
                        textAlign: "center",
                        color: brands[currentBrand].color,
                        filter: "brightness(50%)",
                      }}>
                      {item.productName}
                    </Typography>
                    <YellowButton
                      title={"SZCZEGÓŁY"}
                      url={`/produkty/${item.slug}`}
                      sx={{ width: "230px", textAlign: "center", marginTop: "20px" }}
                    />
                  </Box>
                );
              })}
            </Box>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <Box
          display={currentBrand !== -1 ? (listOfBackgroundBlocks.length > 1 ? "block" : "none") : "block"}
          height={"100px"}
        />
        <Box
          sx={{
            position: "absolute",
            top: { xs: "50px", sm: "100px", lg: "160px" },
            left: "-10",
            height: "985px",
            width: { xs: "2000px", sm: "5000px" },
            zIndex: 9,
            backgroundColor: brands[currentBrand] ? brands[currentBrand].color : palette.primary.main,
            transform: "rotate(-30deg)",
          }}
        />
        {listOfBackgroundBlocks.map((pixels: any, index: number) => {
          return (
            <Box
              key={index}
              display={currentBrand !== -1 ? "block" : "none"}
              sx={{
                position: "absolute",
                top: { xs: `${pixels * 1.5}px`, sm: `${pixels * 1.5}px`, lg: `${pixels}px` },
                left: "-10",
                height: "985px",
                width: { xs: "2000px", sm: "5000px" },
                zIndex: 9,
                backgroundColor: brands[currentBrand] ? brands[currentBrand].color : palette.primary.main,
                transform: "rotate(-30deg)",
              }}
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default BrandsSwitch;
